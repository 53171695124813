import { defineComponent as i, openBlock as n, createElementBlock as o, normalizeClass as r, createElementVNode as t, normalizeStyle as a, createCommentVNode as l, renderSlot as d } from "vue";
import { _ as m } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/CardHero.css';const g = { class: "hero-cover" }, s = /* @__PURE__ */ i({
  __name: "CardHero",
  props: {
    /**
     * Defines the height of the hero.
     *
     * @default 'full'
     */
    heightTmp: {
      type: String,
      default: "medium"
    },
    /**
     * The URL of the image used as a background.
     */
    imgTmp: {
      type: String
    },
    /**
     * Defines the horizontal placement of the background image.
     */
    imgTmpAlignX: {
      type: String,
      default: "center"
    },
    /**
     * Defines the vertical placement of the background image.
     */
    imgTmpAlignY: {
      type: String,
      default: "stretch"
    },
    /**
     * The CSS background of the overlay on top of the cover image.
     */
    overlay: {
      type: String
    },
    /**
     * Defines the width of the content.
     */
    contentWidth: {
      type: String,
      default: "short"
    },
    /**
     * Defines the vertical alignment of the content.
     */
    contentAlignY: {
      type: String,
      default: "bottom"
    },
    /**
     * Defines the padding of the content.
     *
     * - `text` - Applies standard padding like in sections.
     * - `banner` - Sticks the content to the edges on phone screens.
     */
    contentType: {
      type: String,
      default: "text"
    }
  },
  setup(e) {
    return (c, u) => (n(), o("div", {
      class: r(["hero", ["card-hero", `card-hero-${e.heightTmp}`, `hero-align-content-${e.contentAlignY}`]])
    }, [
      t("div", g, [
        t("div", {
          class: "static-cover-image",
          style: a({
            backgroundImage: `url(${e.imgTmp})`,
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundSize: "21rem"
          })
        }, null, 4),
        e.overlay ? (n(), o("div", {
          key: 0,
          class: "hero-cover-overlay",
          style: a({ background: e.overlay })
        }, null, 4)) : l("", !0)
      ]),
      t("div", {
        class: r(["hero-content", [`hero-content-${e.contentWidth}`, `hero-content-${e.contentType}`]])
      }, [
        d(c.$slots, "default", {}, void 0, !0)
      ], 2)
    ], 2));
  }
}), f = /* @__PURE__ */ m(s, [["__scopeId", "data-v-720ca0fd"]]);
export {
  f as default
};
